import React from 'react'

import imagen from '../../images/confirmacion.png'

class Confirmacion extends React.Component{
    render (){
        return (
            <div className='reservas confirmacion'>
                <h4>GRACIAS POR SU RESERVA</h4>
                <p>Le hemos enviado un correo de confirmación a <strong>{window.localStorage.getItem('correo')} (*revisar bandeja de spam o correo no deseado)</strong></p>
                <p><strong>Atención: </strong> su reserva tiene una validez máxima de 10 minutos de la hora que usted selecciono.</p>
                <p>Para consultas comunicarse al:</p>
                <div className='datos'>
                    <div className='wrapper-item'>
                        <img src='https://lacabreraperu.com/images/whatsapp.png' alt='whatsapp' />
                        <p>{this.props.params.local === 'miraflores' ? '(+51) 980527265' : '(+51) 945524829'}</p>
                    </div>
                    <div className='wrapper-item'>
                        <img src='https://lacabreraperu.com/images/phone.png' alt='whatsapp' />
                        <p>{this.props.params.local === 'miraflores' ? '(01) 4473799' : '(01) 6860788'}</p>
                    </div>    
                </div>
                <div className='wrapper-item'>
                    <img src='https://lacabreraperu.com/images/mail.png' alt='whatsapp' />
                    <p>{this.props.params.local === 'miraflores' ? 'reservasmiraflores@lacaberaperu.com' : 'reservaselpolo@lacabreraperu.com'}</p>
                </div>    
                <div className='wrapper-confirmacion'>
                    <img src={imagen} alt='imagen' />
                </div>
            </div>
        )
    }
}

export default Confirmacion