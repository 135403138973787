import React from 'react'

class ReservasApp extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        let childs = this.props.children
            && React.cloneElement(this.props.children)

        return (
            <div className='reservas-app'>
                <div className='wrapper-titulo'>
                    <h1>RESERVACIONES</h1>
                </div>
                <div className='wrapper-descripcion'>
                    <p>Permítanos encargarnos de la gestión de su evento especial</p>
                </div>
                <div className='wrapper-reservas'>
                    {childs}
                </div>
            </div>
        )
    }
}

export default ReservasApp