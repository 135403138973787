import React from 'react'
import update from 'immutability-helper'
import { browserHistory } from 'react-router'

import axios from 'axios'

import pasotres from '../../images/pasotres.png'

class PasoTres extends React.Component {
    constructor (props){
        super (props)
        this.state = {
            datos: {
                nombres: '', 
                apellidos: '',
                correo: '',
                telefono: ''
            },
            error: {
                nombres: false,
                apellidos: false,
                correo: false,
                telefono: false
            },
            reserva: 0,
            boton: false
        }
    }

    componentDidMount = () => {
       axios.get (`/reservas/${this.props.params.local}`)
            .then ((res) => {
                let reservas = res.data.reservas.length 
                this.setState (update (this.state, {
                    reserva: {$set: parseInt(reservas)}
                }))
            }).catch ((err) => {

            })
    }

    handleinput = (e) => {
        e.preventDefault ()

        let id = e.target.id
        let value = e.target.value

        this.setState (update (this.state, {
            datos: {
                [id]: {$set: value}
            }
        }))
    }

    continuar = (e) => {
        e.preventDefault ()

        if (this.state.datos.nombres === '' || this.state.datos.apellidos === '' || 
            this.state.datos.correo === '' || this.state.datos.telefono === ''){
            if (this.state.datos.nombres === ''){
                this.setState (update (this.state, {
                    error: {
                        nombres: { $set: true },
                        apellidos: { $set: false },
                        correo: { $set: false },
                        telefono: { $set: false }
                    }
                }))
            } else if (this.state.datos.apellidos === '') {
                this.setState(update(this.state, {
                    error: {
                        nombres: { $set: false },
                        apellidos: { $set: true },
                        correo: { $set: false },
                        telefono: { $set: false }
                    }
                }))
            } else if (this.state.datos.correo === '') {
                this.setState(update(this.state, {
                    error: {
                        nombres: { $set: false },
                        apellidos: { $set: false },
                        correo: { $set: true },
                        telefono: { $set: false }
                    }
                }))
            } else if (this.state.datos.telefono === '') {
                this.setState(update(this.state, {
                    error: {
                        nombres: { $set: false },
                        apellidos: { $set: false },
                        correo: { $set: false },
                        telefono: { $set: true }
                    }
                }))
            }
        }else{
            let local = this.props.params.local
            let direccion
            let proveedor

            if (local === 'miraflores'){
                proveedor = 'reservasmiraflores@lacabreraperu.com'
                direccion = 'Calle Enrique Palacios 140'
            }else{
                proveedor = 'reservaselpolo@lacabreraperu.com'
                direccion = 'El Polo 284 Urb. Monterrico'
            }

            let numero = this.state.reserva
            let data = {
                reserva: numero + 1 < 10 ? `00000${numero + 1}` : numero + 1 < 100 ? `0000${numero + 1}` : numero + 1 < 1000 ? `000${numero + 1}` : numero + 1 < 10000 ? `00${numero + 1}` : numero + 1 < 100000 ? `0${numero + 1}` : `${numero + 1}`,
                direccion: direccion,
                fecha: window.localStorage.getItem('Fecha'),
                hora: window.localStorage.getItem ('Hora'),
                personas: window.localStorage.getItem('Invitados'),
                nombres: this.state.datos.nombres + ' ' + this.state.datos.apellidos,
                correo: this.state.datos.correo,
                telefono: this.state.datos.telefono,
                local: this.props.params.local,
                proveedor: proveedor
            }

            this.setState (update (this.state, {
                boton: {$set: true}
            }))

            axios.post ('/mensaje', data)
                .then((res) => {

                    window.localStorage.removeItem('Fecha')
                    window.localStorage.removeItem('Hora')
                    window.localStorage.removeItem('Invitados')
                    window.localStorage.setItem('correo', this.state.datos.correo)

                    this.setState(update(this.state, {
                        datos: {
                            nombres: { $set: '' },
                            apellidos: { $set: '' },
                            correo: { $set: '' },
                            telefono: { $set: '' },
                        },
                        error: {
                            nombres: { $set: false },
                            apellidos: { $set: false },
                            correo: { $set: false },
                            telefono: { $set: false }
                        },
                        boton: { $set: false }
                    }))

                    browserHistory.push(`/${this.props.params.local}/confirmacion`)
                    axios.post ('/reserva', data)
                        .then ((res) => {

                        }).catch ((err) => {
                            
                        })
                }).catch((err) => {
                    this.setState(update(this.state, {
                        boton: { $set: false }
                    }))
                })
        }

    }
    
    render() {
        return (
            <div className='reservas'>
                <div className='wrapper-paso-image'>
                    <img src={pasotres} alt='paso-tres' />
                </div>
                <h4>LA CABRERA PERÚ - {this.props.params.local.toUpperCase()}</h4>
                <p style={{color: 'black', fontWeight: 'bold', marginBottom: '0'}}>Detalles de la reservación</p>
                <div className='row'>
                    <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12'>
                        <p>Para el día: {window.localStorage.getItem('Fecha')}, Hora: {window.localStorage.getItem('Hora')}</p>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'>
                        <p>Invitados: {window.localStorage.getItem('Invitados')}</p>
                    </div>
                </div>
                <p style={{ color: 'black', fontWeight: 'bold', marginBottom: '0' }}>Datos personales</p>
                <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='mb-3'>
                            <label htmlFor='nombres' className='form-label'
                                style={{ color: this.state.error.nombres ? 'red' : '#212529' }}>Nombres (*)</label>
                            <input
                                type='text'
                                className='form-control'
                                id='nombres'
                                value={this.state.datos.nombres}
                                onChange={this.handleinput.bind(this)} />
                        </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='mb-3'>
                            <label htmlFor='apellidos' className='form-label'
                                style={{ color: this.state.error.apellidos ? 'red' : '#212529' }}>Apellidos (*)</label>
                            <input
                                type='text'
                                className='form-control'
                                id='apellidos'
                                value={this.state.datos.apellidos}
                                onChange={this.handleinput.bind(this)} />
                        </div>
                    </div>
                    <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='mb-3'>
                            <label htmlFor='correo' className='form-label'
                                style={{ color: this.state.error.correo ? 'red' : '#212529' }}>Correo electrónico (*)</label>
                            <input
                                type='email'
                                className='form-control'
                                id='correo'
                                value={this.state.datos.correo}
                                onChange={this.handleinput.bind(this)} />
                        </div>
                    </div>
                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                        <div className='mb-3'>
                            <label htmlFor='telefono' className='form-label'
                                style={{ color: this.state.error.telefono ? 'red' : '#212529' }}>Teléfono (*)</label>
                            <input
                                type='number'
                                className='form-control'
                                id='telefono'
                                value={this.state.datos.telefono}
                                onChange={this.handleinput.bind(this)} />
                        </div>
                    </div>
                </div>
                <div className='continuar'>
                    <button disabled={this.state.boton} className='btn' onClick={this.continuar.bind(this)}>CONTINUAR</button>
                </div>
            </div>
        )
    }
}

export default PasoTres