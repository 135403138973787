import React from 'react'
import update from 'immutability-helper'
import {browserHistory} from 'react-router'

import axios from 'axios'

import pasouno from '../../images/pasouno.png'

class PasoUno extends React.Component {
    constructor (props){
        super (props)
        this.state = {
            estado: '0'
        }
    }

    componentDidMount = () => {
        axios.get ('/estado/modulo')
            .then ((res) => {
                this.setState(update (this.state, {
                    estado: {$set: res.data.estado.habilitado ? '1' : '2'}
                }))
            }).catch ((err) => {

            })
    }

    rederigir = (e) => {
        e.preventDefault ()

        let id = e.target.id

        browserHistory.push (id)
    }

    render() {
        return (
            <div className='reservas'>
                {
                    this.state.estado === '1' ? (
                        <div>
                            <div className='wrapper-paso-image'>
                                <img src={pasouno} alt='paso-uno' />
                            </div>
                            <h4>SELECCIONE UNO DE NUESTROS LOCALES</h4>
                            <button className='local btn' onClick={this.rederigir.bind(this)} id='/miraflores'>MIRAFLORES</button>
                            <button className='local btn' onClick={this.rederigir.bind(this)} id='/surco'>SURCO</button>
                        </div>                        
                    ) : this.state.estado === '2' ? (
                        <div className='bloqueado'>
                            <h4>EN ESTOS MOMENTOS NO TENEMOS HABILITADO NUESTRO MÓDULO DE RESERVAS</h4>
                            <p>Puede comunicarse a los siguientes números:</p>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 boton'>
                                    <a href={window.outerWidth <= 576 ? 'https://api.whatsapp.com/send?phone=51945524829' : 'https://web.whatsapp.com/send?phone=51945524829'} target='_blank'>
                                        <button className=''>WHATSAPP EL POLO</button>
                                    </a>
                                    <div className='wrapper-item'><img src='https://lacabreraperu.com/images/phone.png' alt='telefono' /><p>(01)6860788</p></div>
                                    <div className='wrapper-item'><img src='https://lacabreraperu.com/images/mail.png' alt='correo' /><p>reservaselpolo@lacabreraperu.com</p></div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 boton'>
                                    <a href={window.outerWidth <= 576 ? 'https://api.whatsapp.com/send?phone=51980527265' : 'https://web.whatsapp.com/send?phone=51980527265'} target='_blank'>
                                        <button className=''>WHATSAPP MIRAFLORES</button>
                                    </a>
                                    <div className='wrapper-item'><img src='https://lacabreraperu.com/images/phone.png' alt='telefono' /><p>(01)4473799</p></div>
                                    <div className='wrapper-item'><img src='https://lacabreraperu.com/images/mail.png' alt='correo' /><p>reservasmiraflores@lacaberaperu.com</p></div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )
    }
}

export default PasoUno