import React from 'react'
import {browserHistory} from 'react-router'
import update from 'immutability-helper'

import pasodos from '../../images/pasodos.png'

import axios from 'axios'

class PasoDos extends React.Component {
    constructor (props){
        super (props)
        this.state = {
            invitados: '2',
            fecha: this.obtenerfecha(0),
            hora: '',
            error: false,
            date: {}
        }
    }

    handleselect = (e) => {
        e.preventDefault ()

        let id = e.target.id
        let value = e.target.value

        if (id !== 'fecha'){
            this.setState (update (this.state, {
                [id]: {$set: value}
            }))
        }else{
            axios.get (`/fechas/${value.split(' ').join('-')}`)
                .then ((res) => {
                    this.setState(update (this.state, {
                        fecha: {$set: value},
                        date: {$set: res.data.fecha}
                    }))
                }).catch ((err) => {

                })
        }
    }

    obtenerfecha = (num) => {
        return (new Date(Date.now() + (24 * 60 * 60 * 1000 * num))).toLocaleDateString("es-PE", { weekday: 'long', yeaer: 'numeric', month: 'long', day: 'numeric' })
    }

    selecthora = (e) => {
        e.preventDefault()

        let id = e.target.id

        this.setState (update (this.state, {
            hora: {$set: id}
        }))
    }

    continuar = (e) => {
        e.preventDefault ()

        if (this.state.hora !== '') {
            window.localStorage.setItem('Fecha', this.state.fecha)
            window.localStorage.setItem('Hora', this.state.hora)
            window.localStorage.setItem('Invitados', this.state.invitados)

            browserHistory.push (`/${this.props.params.local}/datos-personales`)
        }else{
            this.setState (update (this.state, {
                error: {$set: true}
            }))
        }
    }

    render() {
        return (
            <div className='reservas'>
                <div className='wrapper-paso-image'>
                    <img src={pasodos} alt='paso-dos' />
                </div>
                <h4>LA CABRERA PERÚ - {this.props.params.local.toUpperCase()}</h4>
                <div className='row'>
                    <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'>
                        <label className='form-label' htmlFor='invitados'>Personas (*)</label>
                        <select className='form-select mb-3' id='invitados' onChange={this.handleselect.bind(this)}>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>7</option>
                            <option value='8'>8</option>
                            <option value='9'>9</option>
                            <option value='10'>10</option>
                            <option value='11'>11</option>
                            <option value='12'>12</option>
                            <option value='13'>13</option>
                            <option value='14'>14</option>
                        </select>
                    </div>
                    <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'>
                        <label className='form-label' htmlFor='fecha'>Fecha (*)</label>
                        <select className='form-select mb-3' id='fecha' onChange={this.handleselect.bind(this)}>
                            <option value={this.obtenerfecha(1)}>{this.obtenerfecha(1)} (Mañana)</option>
                            <option value={this.obtenerfecha(2)}>{this.obtenerfecha(2)}</option>
                            <option value={this.obtenerfecha(3)}>{this.obtenerfecha(3)}</option>
                            <option value={this.obtenerfecha(4)}>{this.obtenerfecha(4)}</option>
                            <option value={this.obtenerfecha(5)}>{this.obtenerfecha(5)}</option>
                            <option value={this.obtenerfecha(6)}>{this.obtenerfecha(6)}</option>
                            <option value={this.obtenerfecha(7)}>{this.obtenerfecha(7)}</option>
                            <option value={this.obtenerfecha(8)}>{this.obtenerfecha(8)}</option>
                            <option value={this.obtenerfecha(9)}>{this.obtenerfecha(9)}</option>
                            <option value={this.obtenerfecha(10)}>{this.obtenerfecha(10)}</option>
                            <option value={this.obtenerfecha(11)}>{this.obtenerfecha(11)}</option>
                            <option value={this.obtenerfecha(12)}>{this.obtenerfecha(12)}</option>
                            <option value={this.obtenerfecha(13)}>{this.obtenerfecha(13)}</option>
                            <option value={this.obtenerfecha(14)}>{this.obtenerfecha(14)}</option>
                            <option value={this.obtenerfecha(15)}>{this.obtenerfecha(15)}</option>
                            <option value={this.obtenerfecha(16)}>{this.obtenerfecha(16)}</option>
                            <option value={this.obtenerfecha(17)}>{this.obtenerfecha(17)}</option>
                            <option value={this.obtenerfecha(18)}>{this.obtenerfecha(18)}</option>
                            <option value={this.obtenerfecha(19)}>{this.obtenerfecha(19)}</option>
                            <option value={this.obtenerfecha(20)}>{this.obtenerfecha(20)}</option>
                            <option value={this.obtenerfecha(21)}>{this.obtenerfecha(21)}</option>
                            <option value={this.obtenerfecha(22)}>{this.obtenerfecha(22)}</option>
                            <option value={this.obtenerfecha(23)}>{this.obtenerfecha(23)}</option>
                            <option value={this.obtenerfecha(24)}>{this.obtenerfecha(24)}</option>
                            <option value={this.obtenerfecha(25)}>{this.obtenerfecha(25)}</option>
                            <option value={this.obtenerfecha(26)}>{this.obtenerfecha(26)}</option>
                            <option value={this.obtenerfecha(27)}>{this.obtenerfecha(27)}</option>
                            <option value={this.obtenerfecha(28)}>{this.obtenerfecha(28)}</option>
                            <option value={this.obtenerfecha(29)}>{this.obtenerfecha(29)}</option>
                            <option value={this.obtenerfecha(30)}>{this.obtenerfecha(30)}</option>
                            <option value={this.obtenerfecha(31)}>{this.obtenerfecha(31)}</option>
                            <option value={this.obtenerfecha(32)}>{this.obtenerfecha(32)}</option>
                        </select>
                        {
                            this.state.date && (this.state.date.dia && !this.state.date.horas)? (
                                <p style={{color: 'red'}}>No se puede reservar este día</p>
                            ) : null
                        }
                    </div>
                    <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                        <label htmlFor='hora' className='form-label' style={{ color: this.state.error ? 'red' : '#212529'}}>Hora (*)</label>
                        <input type='text' 
                            className='form-control' 
                            id='hora' 
                            placeholder='--'
                            disabled={true}
                            value={this.state.hora}/>
                    </div>
                </div>
                <div className='wrapper-horas'>
                    <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                            <button className='btn' onClick={this.selecthora.bind (this)} id='12:00 p.m'
                                disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[0].doce ? true : false) : false}>12:00 p.m</button>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                            <button className='btn' onClick={this.selecthora.bind (this)} id='01:00 p.m'
                                disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[1].una ? true : false) : false}>01:00 p.m</button>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                            <button className='btn' onClick={this.selecthora.bind (this)} id='02:00 p.m'
                                disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[2].dos ? true : false) : false}>02:00 p.m</button>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                            <button className='btn' onClick={this.selecthora.bind (this)} id='03:00 p.m'
                                disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[4].tres ? true : false) : false}>03:00 p.m</button>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                            <button className='btn' onClick={this.selecthora.bind (this)} id='04:00 p.m'
                                disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[5].cuatro ? true : false) : false}>04:00 p.m</button>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                            <button className='btn' onClick={this.selecthora.bind (this)} id='05:00 p.m'
                                disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[5].cinco ? true : false) : false}>05:00 p.m</button>
                        </div>
                    </div>
                    {
                        this.obtenerfecha(0).split (',')[0] === 'domingo' || this.state.fecha.split (',')[0] === 'domingo' ? (
                            null
                        ) : (
                            <div className='row'>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                    <button className='btn' onClick={this.selecthora.bind (this)} id='06:00 p.m'
                                        disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[6].seis ? true : false) : false}>06:00 p.m</button>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                    <button className='btn' onClick={this.selecthora.bind (this)} id='07:00 p.m'
                                        disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[7].siete ? true : false) : false}>07:00 p.m</button>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                    <button className='btn' onClick={this.selecthora.bind (this)} id='08:00 p.m'
                                        disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[8].ocho ? true : false) : false}>08:00 p.m</button>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                    <button className='btn' onClick={this.selecthora.bind (this)} id='09:00 p.m'
                                        disabled={this.state.date && this.state.date.horas ? (this.state.date.horas[9].nueve ? true : false) : false}>09:00 p.m</button>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className='continuar'>
                    <button className='btn' onClick={this.continuar.bind(this)}
                        disabled={this.state.date && (this.state.date.dia && !this.state.date.horas) ? true : false}>CONTINUAR</button>
                </div>
            </div>
        )
    }
}

export default PasoDos