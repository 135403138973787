import React from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router'
import axios from 'axios'        

import AuthRoute from './util/AuthRoute'
import { Provider } from 'react-redux'

import store from './redux/store.js'

import './styles.css'

import ReservasApp from './components/reservasapp.js'

import PasoUno from './components/reservas/pasouno.js'
import PasoDos from './components/reservas/pasodos.js'
import PasoTres from './components/reservas/pasotres.js'
import Confirmacion from './components/reservas/confirmacion.js'

axios.defaults.baseURL = "https://us-central1-reservas-lacabrera.cloudfunctions.net/web"

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={browserHistory}>

          <AuthRoute path='/' component={ReservasApp}>

            <IndexRoute path='' component={PasoUno} />
            <Route path=':local' component={PasoDos} />
            <Route path=':local/datos-personales' component={PasoTres} />
            <Route path=':local/confirmacion' component={Confirmacion} />

          </AuthRoute>

        </Router>
      </Provider>
    )
  }
}

export default App;